@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,500&display=swap");
@import url("//hello.myfonts.net/count/3e947d");

:root {
  --primaryColor: #028090;
  --secondaryColor: #e4fde1;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
    box-sizing: border-box;
  }

  
  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
    font-family: sans-serif;
    color: #fff;

     /* Should be removed. Only for demonstration */
  }
  .column3 {
    float: left;
    width: 25%;
    padding: 1rem 2rem 1rem 2rem;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  .column4 {
    float: left;
    width:50%;
    padding: 1rem 2rem 1rem 2rem;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  .column5 {
    float: left;
    width: 50%;
    padding: 1rem 2rem 1rem 2rem;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  .column6 {
    float: left;
    width: 30%;
    padding: 1rem 2rem 1rem 2rem;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  .column7 {
    float: left;
    width: 70%;
    padding: 1rem 2rem 1rem 2rem;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  .pic{
    z-index: 3;
    max-width: 1200px;
    position: absolute; 
    padding: 16px;
    display: flex;
    /* width: 600px; */
    background-color: rgba(74,0,51,0.7);
    flex-direction: column;
    align-items: left;
  }
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .column1 {
    float: left;
    width: 50%;
    padding: 10px;
    font-family: sans-serif;
    color: #fff;

     /* Should be removed. Only for demonstration */
  }

  .column2 {
    float: left;
    width: 50%;
    /* padding: 10px; */
    font-family: sans-serif;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  
  .column11{
    width: 33.3%;
    float: left;
  }
  .column12{
    width: 33.3%;
  }
  .column13{
    width: 33.3%;
    float:right;
  }
  .row1{
    content: "";
    display: table;
    clear: both;
  }
  /* Clear floats after the columns */
  .row1:after {
    content: "";
    display: table;
    clear: both;
  }
/* body {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: #1f2029;
  background-color: #fff;
  overflow: hidden;
  background-image: url("https://st4.depositphotos.com/1692783/21101/i/1600/depositphotos_211011760-stock-photo-blue-abstract-watercolor-background-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 101%;
  width: 100%;
  height: 100vh;
} */

.content {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #4a0033;
}

.content1 {
    /* display: flex; */
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #4a0033;
  }

.text {
  padding: 2rem;
  color: #fff;
  max-width: 50%;
}

/*.content img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}*/

.quote{
    font-size: 1.5rem;
    font-family: "Lucida";
    padding: 1.3rem;
    color: #fff;
}

.author{
    font-size: 1.5rem;
    font-family: "Lucida";
    padding-left: 70rem;
    color: #fff;
}

.quoteDiv{
    padding: 2rem;
}

@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .content img {
    width: 100%;
  }

  .column {
    width: 100%;
  }
}
.left-col{
  width: 50%;
  float:left;
  padding: 2vw;
  font-size: 1.5rem;
}

.right-col{
  width: 50%;
  float:right;
  padding: 2vw;
  font-size: 1.5rem;
}

.credsCDH{
  padding: 2vw;
  font-size: 1.5rem;
  text-align:center;
}

.logo{
  margin: 0 5%;
}
@media screen and (max-width: 480px) {
  .column1 {
    width: 100%;
  }
}
