@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,500&display=swap");
@import url("//hello.myfonts.net/count/3e947d");

:root {
  --primaryColor: #028090;
  --secondaryColor: #e4fde1;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
    box-sizing: border-box;
  }
  
  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    width: 33.33%;
    padding: 10px;
    font-family: sans-serif;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .column1 {
    float: left;
    width: 50%;
    padding: 10px;
    font-family: sans-serif;
    color: #fff;
     /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row1:after {
    content: "";
    display: table;
    clear: both;
  }
/* body {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: #1f2029;
  background-color: #fff;
  overflow: hidden;
  background-image: url("https://st4.depositphotos.com/1692783/21101/i/1600/depositphotos_211011760-stock-photo-blue-abstract-watercolor-background-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 101%;
  width: 100%;
  height: 100vh;
} */

.content {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #4a0033;
}

.content1 {
    /* display: flex; */
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #4a0033;
  }

.text {
  padding: 2rem;
  color: #fff;
  max-width: 420px;
  font-size: 1.5rem;
}

.content img {
  height: 100%;
  /* object-fit: cover; */
}

.quote{
    font-size: 1.5rem;
    font-family: "Lucida";
    padding: 1.3rem;
    color: #fff;
}

.author{
    font-size: 1.5rem;
    font-family: "Lucida";
    padding-left: 38rem;
    color: #fff;
}

.quoteDiv{
    padding: 2rem;
}

@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .content img {
    width: 100%;
  }
}

.selector {
  cursor: pointer;
}