.mosaic{
    overflow:hidden;
}

.selector {
    overflow: hidden;
}

.selector:hover {
    cursor: pointer;
}