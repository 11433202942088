

@font-face {
  font-family: 'Lucida';
  src: url('LHANDW.TTF'); /* IE9 Compat Modes */
  src: url('LHANDW.TTF')  format('truetype'), /* Safari, Android, iOS */

}

@font-face{
  font-family: 'Cancione';
  src: url('CancioneITCStd.woff');
}

/* @import url("https://use.typekit.net/qpc1jsr.css"); */
  
/* @font-face {
  font-family: "CancioneITCStd";
  src: url('webFonts/CancioneITCStd/font.woff2') format('woff2'), url('webFonts/CancioneITCStd/font.woff') format('woff');
} */


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Myriad Pro Regular";
  line-height: ch;
  overflow-x: hidden; 
}

.credits{
  font-size: 1.5vmin;
}

.modalText{
  font-size: 1rem;
}

h1 {
  font-family: "Cancione";
  font-size: 2.5rem;
}
/*h1 {
  font-family: "CancioneITCStd"
}*/
/* html, body, #root, .App {
  height: 100%;
} */

.textile:hover{
  width: 100px;
}

